import styled, { keyframes } from 'styled-components'

export const fade = keyframes`
    from { opacity: 1; }
    to { opacity: 0; }
`

export const FadeIn = styled.div.attrs({className: 'class-FadeIn'})`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: #ffd863;
    animation: ${fade} 4s normal forwards ease-in-out;
`

export const Container = styled.div.attrs({className: 'class-Container'})`
    font-family: 'Inter';
    font-size: 16px;
    & h1 {
        padding: 0;
        margin: 0 0 0.05em 0;
        font-family: 'Ayer Poster', serif;
        font-weight: 400;
        font-size: min(18vw, 14em);
        line-height: 0.85em;
    }
`

export const Page = styled.div
.attrs(props =>({
    className: 'class-Page',
    bg: props.bg || 'gray'
}))`
    position: relative;
    /* background-color: ${props => props.bg};
    opacity: 0.5; */
    width: 100%;
    height: 100vh;
    border: solid black;
    border-width: 2px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    & p {
        font-size: 10em;
    }
`

export const Screen = styled.div
.attrs(props =>({
    className: 'class-Screen',
    bg: props.bg || 'gray'
}))`
    position: relative;
    background-color: ${props => props.bg};
    height: 100vh;
`

export const TopLeft = styled.div.attrs({className: 'class-TopLeft'})`
    position: absolute;
    top: 5vw;
    left: 5vw;
`

export const BottomLeft = styled.div.attrs({className: 'class-BottomLeft'})`
    position: absolute;
    bottom: 5vw;
    left: 5vw;
    width: 30ch;
    max-width: 40%;
`

export const BottomRight = styled.div.attrs({className: 'class-BottomRight'})`
    position: absolute;
    bottom: 5vw;
    right: 5vw;
    width: 35ch;
    max-width: 40%;
    line-height: 1em;
    letter-spacing: -0.01em;
    text-align: right;
`

export const Hamburger = styled.div.attrs({className: 'class-Hamburger'})`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5vw;
    right: 5vw;
    & > div {
        position: relative;
        width: 24px;
        height: 2px;
        background: #252525;
        margin-bottom: 6px;
    }
`

export const LeftMiddle = styled.div.attrs({className: 'class-LeftMiddle'})`
    position: absolute;
    bottom: 50%;
    right: 5vw;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 1em;
    letter-spacing: -0.01em;
    font-size: 12px;
    transform: rotate(90deg) translate3d(50%, 0, 0);
    transform-origin: 100% 50%;
`
