import { createRoot } from 'react-dom/client'
import { Suspense, useState } from 'react'

import './styles.css'

import Overlay from './layout/Overlay'
import { FadeIn, LeftMiddle } from './layout/styles'

import Experience from './Experience'
import SplineWork from './Spline'

function App() {
    const [speed, setSpeed] = useState(1)
    return (
        <>
            {/* suspense wrapper for concurrent rendering */}
            <Suspense fallback={null}>
                {/* actual webgl canvas */}
                <Experience speed={speed} />
                {/* overlay to use as fadein for canvas */}
                {/* <FadeIn /> */}

            </Suspense>

            {/*<SplineWork />*/}

            {/* <Overlay /> */}
            
            {/* speed control */}
            {/* <LeftMiddle>
                <input type="range" min="0" max="10" value={speed} step="1" onChange={(e) => setSpeed(e.target.value)} />
            </LeftMiddle> */}
        </>
    )
}

createRoot(document.getElementById('root')).render(<App />)
