import * as THREE from 'three'
import { useRef, useState, useEffect } from 'react'
import { Canvas, useThree, useFrame } from '@react-three/fiber'
// https://github.com/pmndrs/drei
import { useGLTF, Detailed, Environment, ScrollControls, Scroll, useScroll, Center, Image } from '@react-three/drei'
// https://github.com/pmndrs/react-postprocessing
// https://github.com/vanruesc/postprocessing
import { EffectComposer, DepthOfField, Glitch, Pixelation } from '@react-three/postprocessing'
import { Perf } from 'r3f-perf'

import Screen from './layout/Screen.jsx'

function Duck(props) {
	const group = useRef()
	const { nodes, materials } = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/duck/model.gltf')

	const scrollData = useScroll()

	const { width, height } = useThree((state) => state.viewport)
	console.log(width,height)

	useFrame((state, dt) => {
		if (dt < 0.1) {
			// group.current.position.z = -85 + (scrollData.offset * 85) // 85 is camera depth
			group.current.rotation.z = scrollData.offset * Math.PI * 2
			// group.current.rotation.y += Math.PI * dt / 2

			// group.current.position.set(
			//     (-width) * scrollData.offset,
			//     1,
			//     -1
			// )
		}
	})

	return (
		<Center>
			<group ref={group} {...props} dispose={null} scale={0.1}>
				<mesh geometry={nodes.character_duck.geometry} material={nodes.character_duck.material} rotation={[Math.PI / 2, 0, 0,]} >
				<mesh geometry={nodes.character_duckArmLeft.geometry} material={nodes.character_duckArmLeft.material} position={[0.2, 0, -0.63,]} />
				<mesh geometry={nodes.character_duckArmRight.geometry} material={nodes.character_duckArmRight.material} position={[-0.2, 0, -0.63,]} />
				<group position={[0, 0, -0.7,]} >
					<mesh geometry={nodes.Cube1338.geometry} material={nodes.Cube1338.material} />
					<mesh geometry={nodes.Cube1338_1.geometry} material={materials['Yellow.043']} />
					<mesh geometry={nodes.Cube1338_2.geometry} material={materials['Black.027']} />
				</group>
				</mesh>
			</group>
		</Center>
	)
}
useGLTF.preload('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/duck/model.gltf')

function Banana({ index, z, speed }) {
	const ref = useRef()
	// console.log(index, z)
	// useThree gives you access to the R3F state model
	const { viewport, camera } = useThree()
	// getCurrentViewport is a helper that calculates the size of the viewport
	// different for each instance because of their z position
	const { width, height } = viewport.getCurrentViewport(camera, [0, 0, -z])

	// useGLTF is an abstraction around R3F's useLoader(GLTFLoader, url)
	// It can automatically handle draco and meshopt-compressed assets without you having to
	// worry about binaries and such ...
	const { nodes, materials } = useGLTF('/banana-v1-transformed.glb')
	// By the time we're here the model is loaded, this is possible through React suspense

	// Local component state, it is safe to mutate because it's fixed data
	const [data] = useState({
		// Randomly distributing the objects along the vertical
		y: THREE.MathUtils.randFloatSpread(height * 2),
		// This gives us a random value between -1 and 1, we will multiply it with the viewport width
		x: THREE.MathUtils.randFloatSpread(2),
		// How fast objects spin, randFlost gives us a value between min and max, in this case 8 and 12
		spin: THREE.MathUtils.randFloat(8, 12),
		// Some random rotations, Math.PI represents 360 degrees in radian
		rX: Math.random() * Math.PI,
		rZ: Math.random() * Math.PI,

		// added
		// cap z at 10 so it's not too close
		z: z < 10 ? 10 : z
	})

	const scrollData = useScroll()
	// console.log(scrollData)

	// set materials to allow transparency
	useEffect(() => {
		for (let i=0; i<ref.current.children.length; i++) {
			ref.current.children[i].material.transparent = true
		}
	}, [])

	// console.log(index, ref.current)

	// useFrame executes 60 times per second
	useFrame((state, dt) => {
		// # of pages is 3
		// but scroll distance is 3-1=2 because the first "page" is the view
		const p1Visible = scrollData.visible(0, 1/(scrollData.pages-1))
		const p2Visible = scrollData.visible(1/(scrollData.pages-1), 1/(scrollData.pages-1))
		const p3Visible = scrollData.visible(2/(scrollData.pages-1), 1/(scrollData.pages-1))

		const p1Range= scrollData.range(0/(scrollData.pages-1), 1/(scrollData.pages-1))
		const p2Range= scrollData.range(1/(scrollData.pages-1), 1/(scrollData.pages-1))
		const p3Range= scrollData.range(2/(scrollData.pages-1), 1/(scrollData.pages-1))

		// Make the X position responsive, slowly scroll objects up at the Y, distribute it along the Z
		// dt is the delta, the time between this frame and the previous, we can use it to be independent of the screens refresh rate
		// We cap dt at 0.1 because now it can't accumulate while the user changes the tab, it will simply stop
		
		if (dt < 0.1) {
			ref.current.position.set(
				// first banan will always be centered
				index === 0 ? 0 : data.x * width, 
				// 0,
				
				(data.y += dt * speed), 
				// data.y,

				// -z
				-data.z
			)
		
			// Rotate the object around
			ref.current.rotation.set(
				(data.rX += dt / data.spin), 
				Math.sin(index * 1000 + state.clock.elapsedTime / 10) * Math.PI, 
				(data.rZ += dt / data.spin)
			)
			
			// If they're too far up, set them back to the bottom
			// console.log(data.y, height, height * 4)
			if (data.y > height * (index === 0 ? 4 : 1)) 
				data.y = -(height * (index === 0 ? 4 : 1))

			// when p1 is in view
			if (p1Visible) {
				// console.log('p1 visible')
				// ref.current.rotation.z = (data.rZ += dt / data.spin)
				// for (let i=0; i<ref.current.children.length; i++) {
				//     ref.current.children[i].material.opacity = 1-p1Range
				// }
				// ref.current.position.z = -data.z
			} else {
				// ref.current.children[0].material.opacity = 0
				// ref.current.position.z = -80
			}

			// if (p2Visible)
			//     ref.current.rotation.z = (data.rZ -= dt / data.spin)
			// if (p3Visible)
			//     ref.current.rotation.z = (data.rZ += dt / data.spin)

			// data.rX += dt / data.spin
			// data.rZ += dt / data.spin

			// if(p3Visible)
			//     // ref.current.rotation.set(
			//     //     (data.rX += dt / data.spin), 
			//     //     Math.sin(index * 1000 + state.clock.elapsedTime / 10) * Math.PI, 
			//     //     (data.rZ += dt / data.spin)
			//     // )
			//     ref.current.rotation.set(
			//         data.rX, 
			//         // Math.sin(index * 1000 + state.clock.elapsedTime / 10) * Math.PI, 
			//         0,
			//         data.rZ
			//     )
		} 
		// else {
		//     console.log(dt);    
		// }
	})

	// Using drei's detailed is a nice trick to reduce the vertex count because
	// we don't need high resolution for objects in the distance. The model contains 3 decimated meshes ...
	return (
		<Detailed ref={ref} distances={[0, 65, 80]}>
			<mesh geometry={nodes.banana_high.geometry} material={materials.skin} material-emissive="#ff9f00" />
			<mesh geometry={nodes.banana_mid.geometry} material={materials.skin} material-emissive="#ff9f00" />
			<mesh geometry={nodes.banana_low.geometry} material={materials.skin} material-emissive="#ff9f00" />
		</Detailed>
	)
}

function Photo({ c = new THREE.Color(), ...props }) {
	const ref = useRef()
	const [hovered, hover] = useState(false)

	// useFrame(() => {
	//     ref.current.material.color.lerp(c.set(hovered ? 'white' : '#ccc'), hovered ? 0.4 : 0.05)
	// })
	return <Image ref={ref} onPointerOver={() => hover(true)} onPointerOut={() => hover(false)} {...props} />
}

function Images() {
	const { width, height } = useThree((state) => state.viewport)
	const scrollData = useScroll()
	const group = useRef()

	useFrame(() => {
		// left image
		group.current.children[0].material.zoom = 1 + scrollData.range( 1/2, 1/2 )

		// move right image if it's far to the left
		// if (group.current.children[2].position.x > width/2)
			group.current.children[2].position.x = width-(scrollData.range( 1/2, 1/2 )*2)

		// group.current.children[3].material.zoom = 1 + scrollData.range(1.15 / 3, 1 / 3) / 2
		// group.current.children[4].material.zoom = 1 + scrollData.range(1.25 / 3, 1 / 3) / 1
		// group.current.children[5].material.zoom = 1 + scrollData.range(1.8 / 3, 1 / 3) / 3
		group.current.children[1].material.grayscale = scrollData.range( 1/2, 1/2 )
		// group.current.children[6].material.zoom = 1 + (1 - scrollData.range(2 / 3, 1 / 3)) / 3
	})
	// console.log(width,height);
	return (
		<group ref={group}>
			<Image transparent position={[-2, -height*2, 0]} scale={[2, height/2, 1]} url="/img1.jpg" />
			<Image transparent position={[-0.6, -height*2, -10]} scale={[width/2, height, 1]} url="/img4.jpg" />
			<Image transparent position={[width, -height*2, -5]} scale={[3, 3, 1]} url="/img3.jpg" />
		</group>
	)
}

export default function Experience({ 
	speed = 1, 
	count = 80, 
	depth = 80, 
	easing = (x) => Math.sqrt(1 - Math.pow(x - 1, 2)) 
}) {
	return (
		<div id="canvas-container">
			{/* No need for antialias (faster), dpr clamps the resolution to 1.5 (also faster than full resolution) */}
			<Canvas
				gl={{ antialias: false }} 
				dpr={[1, 1.5]} 
				camera={{ position: [0, 0, 10], fov: 20, near: 0.01, far: depth + 15 }}
			>
				<color attach="background" args={['#ffbf40']} />
				<spotLight position={[10, 20, 10]} penumbra={1} intensity={3} color="orange" />
				{/* <Environment preset="sunset" /> */}

				{/* Using cubic easing here to spread out objects a little more interestingly, i wanted a sole big object up front ... */}
				{/* {Array.from(
					{ length: count }, 
					(_, i) => 
						<Banana 
							key={i} 
							index={i} 
							z={Math.round(easing(i / count) * depth)} 
							speed={speed} 
						/>
				)} */}
				
				{/* Multisampling (MSAA) is WebGL2 antialeasing, we don't need it (faster) */}
				{/* <EffectComposer multisampling={0}> */}
					{/* <DepthOfField target={[0, 0, 60]} focalLength={0.4} bokehScale={14} height={700} /> */}
					{/* <Glitch /> */}
					{/* <Pixelation granularity={20} /> */}
				{/* </EffectComposer> */}

				<Perf position="top-right" />

				<ScrollControls damping={0} pages={3}>
					{/* <Duck /> */}
					<Scroll>
						{Array.from(
							{ length: count }, 
							(_, i) => 
								<Banana 
									key={i} 
									index={i} 
									z={Math.round(easing(i / count) * depth)} 
									speed={speed} 
								/>
						)}
					</Scroll>

					<Scroll html style={{width: '100%'}}>
						<Screen index={1} bg='#ffd863' />
						<Screen index={2} bg='#58b0d2' />
						<Screen index={3} bg='#46aa46' />
					</Scroll>

					<Scroll>
						<Images />
					</Scroll>
				</ScrollControls>

			</Canvas>
		</div>
	)
}
