import { Page } from './styles'

export default function Screen(props) {
    return (
        <Page {...props} 
            // style={{ backgroundColor: props.bg }}
        >
            <p>Page {props.index}</p>
        </Page>
    )
}
